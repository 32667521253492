var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010201" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c("c-appr-btn", {
                              ref: "appr-btn",
                              attrs: {
                                name: "patrol-month-appr-btn",
                                editable: _vm.editable,
                                approvalInfo: _vm.approvalInfo,
                              },
                              on: {
                                beforeApprAction: _vm.saveDataAppr,
                                callbackApprAction: _vm.approvalCallback,
                                requestAfterAction: _vm.getDetail,
                              },
                            }),
                            _vm.isOld && !_vm.disabled && _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBLREMOVE",
                                    icon: "delete_forever",
                                  },
                                  on: { btnClicked: _vm.removeData },
                                })
                              : _vm._e(),
                            (_vm.isOld && !_vm.disabled && _vm.editable) ||
                            !_vm.isOld
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                        },
                        [
                          _c("c-text", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && !_vm.disabled,
                              label: "LBL0010198",
                              name: "proceedingsTitle",
                            },
                            model: {
                              value: _vm.data.proceedingsTitle,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "proceedingsTitle", $$v)
                              },
                              expression: "data.proceedingsTitle",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-datepicker", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && !_vm.disabled,
                              label: "LBL0010199",
                              name: "proceedingsDate",
                            },
                            model: {
                              value: _vm.data.proceedingsDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "proceedingsDate", $$v)
                              },
                              expression: "data.proceedingsDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                        },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: "",
                              editable: _vm.editable && !_vm.disabled,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "grid",
                    attrs: {
                      title: "회의결과",
                      columns: _vm.grid.columns,
                      gridHeight: "400px",
                      data: _vm.data.opinions,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      noDataLabel: "회의결과를 등록하세요",
                      hideBottom: true,
                      checkClickFlag: false,
                      isExcelDown: false,
                      editable: _vm.editable && !_vm.disabled,
                      rowKey: "proceedingsOpinionId",
                      selection: "multiple",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            (_vm.isOld && !_vm.disabled && _vm.editable) ||
                            !_vm.isOld
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addOpinion },
                                })
                              : _vm._e(),
                            ((_vm.isOld && !_vm.disabled && _vm.editable) ||
                              !_vm.isOld) &&
                            _vm.data.opinions.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLREMOVE",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeOpinion },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c(
                  "c-table",
                  {
                    ref: "inTable",
                    attrs: {
                      title: "LBL0010203",
                      columns: _vm.inGrid.columns,
                      gridHeight: "260px",
                      data: _vm.data.attendeeInModels,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      noDataLabel: "MSG0010010",
                      hideBottom: true,
                      editable: _vm.editable && !_vm.disabled,
                      checkClickFlag: false,
                      isExcelDown: false,
                      rowKey: "proceedingsAttendeeId",
                      selection: "multiple",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            (_vm.isOld && !_vm.disabled && _vm.editable) ||
                            !_vm.isOld
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addInAttendee },
                                })
                              : _vm._e(),
                            ((_vm.isOld && !_vm.disabled && _vm.editable) ||
                              !_vm.isOld) &&
                            _vm.data.attendeeInModels.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLREMOVE",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeInAttendee },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c(
                  "c-table",
                  {
                    ref: "outTable",
                    attrs: {
                      title: "LBL0010204",
                      columns: _vm.outGrid.columns,
                      gridHeight: "260px",
                      data: _vm.data.attendeeOutModels,
                      filtering: false,
                      columnSetting: false,
                      usePaging: false,
                      noDataLabel: "MSG0010011",
                      hideBottom: true,
                      checkClickFlag: false,
                      isExcelDown: false,
                      editable: _vm.editable && !_vm.disabled,
                      rowKey: "proceedingsAttendeeId",
                      selection: "multiple",
                    },
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            (_vm.isOld && !_vm.disabled && _vm.editable) ||
                            !_vm.isOld
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLADD",
                                    icon: "add",
                                  },
                                  on: { btnClicked: _vm.addOutAttendee },
                                })
                              : _vm._e(),
                            ((_vm.isOld && !_vm.disabled && _vm.editable) ||
                              !_vm.isOld) &&
                            _vm.data.attendeeOutModels.length > 0
                              ? _c("c-btn", {
                                  attrs: {
                                    showLoading: false,
                                    label: "LBLREMOVE",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.removeOutAttendee },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "첨부파일" },
                  },
                  [
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-12 col-md-12 col-lg-12" },
                        [
                          _c("c-upload", {
                            attrs: {
                              attachInfo: _vm.attachInfo,
                              editable: _vm.editable && !_vm.disabled,
                              label: "첨부파일",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }